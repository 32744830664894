import React, { FC, useContext, useEffect, useRef, useState } from 'react'

import SectionTitle from '@components/ui/sectionTitle'
import Link, { navigate } from '@components/ui/link'
import Text from '@components/ui/text'
import config from '@utils/config'
import { PageTitle } from '@components/configs/PageTitle'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { formatStrapiText, getParamsFromSearchLocation } from '@utils/methods'
import { Request, RequestSubTheme, RequestTheme } from 'typings/shared'
import { PageProps } from 'gatsby'
import * as pageUtils from '@pages/auth/requests/create/__index.utils'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import {
	IDENTIFIERS_OF_TN_REQUEST_TYPE,
	LanguageEnum
} from '@services/constants'
import { authRedirectPage, loginRequest } from '@utils/authConfig'
import { joinClasses } from '@utils/styles'
import OutsideClickHandler from 'react-outside-click-handler'
import Collapse from '@components/ui/collapse'
import { ChevronDown } from 'react-bootstrap-icons'
import axios from 'axios'
import Loader from '@components/ui/loader'
import { Request as MyRequest } from '@services/models'
import CancelRequestModal from '@components/ui/cancelRequestModal'
import { useAppDispatch, useAppSelector } from '@services/store'
import { getRequestList } from '@services/store/requestList/selector'
import { setRequestListStore } from '@services/store/requestList'

const CreateRequest: FC<PageProps<null, null, null>> = ({
	location: { search }
}) => {
	const { instance } = useMsal()
	const { pageData, language, authUser } = useContext(AppStateContext)
	const isUserConnected: boolean = useIsAuthenticated()
	const params: URLSearchParams = getParamsFromSearchLocation(search)
	const group: string = params['group'] || config.request.create.group.default
	const tempTheme: RequestTheme | undefined =
		pageData?.collections?.requestThemes.filter(
			(request: RequestTheme) => request.name === group
		)[0]

	const [theme, setTheme] = useState<RequestTheme | undefined>(tempTheme)
	const [otherRequest, setOtherRequest] = useState<Request>()
	const [isOtherRequestAvailable, setIsOtherRequestAvailable] =
		useState<boolean>(false)
	const [doDisplayOtherSection, setDoDisplayOtherSection] =
		useState<boolean>(false)

	const [showDropdown, setShowDropdown] = useState<boolean>(false)
	const [filterList, setFilterList] = useState<string[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [pageSubtitle, setPageSubtitle] = useState<string>('')
	const [requests, setRequests] = useState<Request[]>([])
	const [tmpRequests, setTmpRequests] = useState<Request[]>([])
	const [displayedRequests, setDisplayedRequests] = useState<Request[]>([])
	const [myRequests, setMyRequests] = useState<MyRequest[]>([])
	const [loadingMyRequests, setLoadingMyRequests] = useState<boolean>(false)
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
	const requestListStore = useAppSelector(getRequestList)
	const dispatch = useAppDispatch()

	const filterRef = useRef<HTMLFormElement>(null)

	useEffect(() => {
		setTheme(tempTheme)
		setPageSubtitle(tempTheme?.title!)
		setRequests(tempTheme?.requests!)
	}, [group, tempTheme])

	useEffect(() => {
		setLoading(true)
		axios
			.get(
				`${process.env.STRAPI_API}/api/ptc-requests?pagination%5BpageSize%5D=${pageUtils.numberMaxOfRequest}`
			)
			.then(async (res) => {
				const data = res.data as pageUtils.strapiDataRes
				const hiddenRequestList = data.data.map((request) => {
					if (pageUtils.REQUEST_HIDDEN[request.attributes.Cacher])
						return request.attributes.request_id
				})
				const tempRequests = requests?.filter(
					(request) => !hiddenRequestList.includes(request.requestId)
				)

				setTmpRequests(tempRequests)
			})
			.catch((e) => {
				setTmpRequests(requests)
				console.error(e)
			})
			.finally(() => setLoading(false))
	}, [requests])

	useEffect(() => {
		const subThemeFound: RequestSubTheme | undefined =
			theme?.requestSubThemes.find(
				(subtheme: RequestSubTheme) =>
					subtheme.name === config.request.create.group.other
			)

		if (subThemeFound) {
			const otherRequestFound: Request | undefined =
				subThemeFound.requests.find(
					(request: Request) =>
						request.name === config.request.create.otherRequest
				)

			if (subThemeFound.requests.length > 1) {
				setDoDisplayOtherSection(true)
			}

			if (otherRequestFound) {
				setOtherRequest(otherRequestFound)
				setIsOtherRequestAvailable(true)
			}
		}

		return () => {
			setOtherRequest(undefined)
			setIsOtherRequestAvailable(false)
			setDoDisplayOtherSection(false)
		}
	}, [theme])

	const fetchRequests = async () => {
		if (
			authUser &&
			group == 'registrationsAndApplications' &&
			loadingMyRequests == false
		) {
			if (requestListStore.fetched) {
				setMyRequests(requestListStore.requests)
			} else {
				try {
					setLoadingMyRequests(true)

					const requestsList = await authUser.fetchRequests(false)

					setMyRequests(requestsList)
					dispatch(
						setRequestListStore({
							fetching: false,
							fetched: true,
							requests: requestsList
						})
					)
				} catch (error: any) {
					console.error(error?.message)
				} finally {
					setLoadingMyRequests(false)
					// setTimeout(() => setLoadingMyRequests(false), 200)
				}
			}
		}
	}

	// useEffect(() => {
	// 	fetchRequests().catch(console.error)
	// }, [])

	useEffect(() => {
		fetchRequests().catch(console.error)
	}, [authUser, group])

	const onNavigateToRequest = (requestId: string) => (e) => {
		e.preventDefault()

		const link: string = `${config.request.create.baseURL}/${requestId}`

		if (!isUserConnected) {
			const authRedirect: string = config.localStorage.authRedirect
			// this will send the right language to display on Azure AD B2C
			const extraQueryParameters = {
				ui_locales: `${language || LanguageEnum.FR}`
			}

			localStorage.setItem(authRedirect, link)

			instance.loginRedirect({
				...loginRequest,
				redirectUri: authRedirectPage,
				extraQueryParameters
			})

			return
		}

		navigate(link)
	}

	const handleFilter = (
		e: React.ChangeEvent<HTMLInputElement>,
		name: string
	) => {
		if (e.target.checked && !filterList.includes(name)) {
			const tempList = [...filterList, name]
			setFilterList(tempList)
		}

		if (!e.target.checked && filterList.includes(name)) {
			const tempList = [...filterList].filter((el) => el !== name)
			setFilterList(tempList)
		}
	}

	const filterRequests = (requests: Request[], filterList: string[]) => {
		const temp = !!filterList.length
			? requests.filter(
					(request) =>
						!!request.subThemes.find((subTheme) =>
							filterList.includes(subTheme)
						)
			  )
			: requests

		setDisplayedRequests(temp)
	}

	const getSubThemes = (subTheme: string, key: number) => {
		const title = theme?.requestSubThemes.find(
			(subThm) => subThm.name == subTheme
		)?.title
		return (
			<span key={`${subTheme}-${key}`} className={pageUtils.classes.category}>
				{title}
			</span>
		)
	}

	useEffect(() => {
		filterRequests(tmpRequests, filterList)
	}, [tmpRequests, filterList])

	useEffect(() => {
		setFilterList([])
	}, [theme])

	useEffect(() => {
		const boxes = filterRef?.current?.elements
		if (!!boxes) {
			for (let i = 0; i < boxes?.length!; i++) {
				const box = boxes?.item(i) as HTMLInputElement
				box.checked = false
			}
		}
	}, [group])

	const getPublicAuctionsRequestIfExists = () => {
		const publicAuction = myRequests.filter(
			(request) =>
				request.typeId == IDENTIFIERS_OF_TN_REQUEST_TYPE.PUBLIC_AUCTIONS &&
				request.cancelReason == null
		)

		if (publicAuction.length > 0) {
			return publicAuction[0]
		}

		return undefined
	}

	const displayRequests = (request: Request, key: number) => {
		let publicAuction = getPublicAuctionsRequestIfExists()

		if (request.requestId == IDENTIFIERS_OF_TN_REQUEST_TYPE.PUBLIC_AUCTIONS) {
			return (
				<div key={request.requestId} className={pageUtils.classes.accordion}>
					<Collapse
						id={key}
						buttonText={
							<>
								<div>
									<span className={pageUtils.classes.btnTitle}>
										{request.title}
									</span>
									<div className={pageUtils.classes.tag}>
										{request.subThemes.map(getSubThemes)}
									</div>
								</div>
							</>
						}
					>
						<div
							className={joinClasses([
								pageUtils.classes.textContent,
								pageUtils.classes.panel
							])}
						>
							<Text content={request.description} />

							{loadingMyRequests ? (
								<button
									onClick={onNavigateToRequest(request.requestId)}
									className={pageUtils.classes.btn}
								>
									<div className={`${pageUtils.classes.loader} spin`} />
								</button>
							) : publicAuction && publicAuction.cancelReason == null ? (
								<>
									<div className={pageUtils.classes.requestInfoSection}>
										<>
											{formatStrapiText(
												pageData?.assets.request_form_registered_since_text
											)}{' '}
											{publicAuction?.dateAdd?.localDateString}
										</>
										<br />
										<>
											{formatStrapiText(
												pageData?.assets
													.request_form_confirmation_request_number_text
											)}{' '}
											: <span>{publicAuction?.code}</span>
										</>
									</div>
									<button
										onClick={() => {
											setIsOpenModal(true)
										}}
										className={pageUtils.classes.btn}
									>
										{formatStrapiText(
											pageData?.assets.request_form_cancel_my_registration
										)}
									</button>
								</>
							) : (
								<button
									onClick={onNavigateToRequest(request.requestId)}
									className={pageUtils.classes.btn}
								>
									{formatStrapiText(
										pageData?.assets[pageUtils.buttonTextDic[group]]
									)}
								</button>
							)}
						</div>
					</Collapse>
					<CancelRequestModal
						onCancel={() => setIsOpenModal(false)}
						isOpen={isOpenModal}
						request={publicAuction}
						title={pageData?.assets?.request_form_cancel_registration}
						description={
							pageData?.assets.request_form_publicAuctions_cancel_registration
						}
						isDefaultCancelReason
					/>
				</div>
			)
		}

		return (
			<div key={request.requestId} className={pageUtils.classes.accordion}>
				<Collapse
					id={key}
					buttonText={
						<>
							<div>
								<span className={pageUtils.classes.btnTitle}>
									{request.title}
								</span>
								<div className={pageUtils.classes.tag}>
									{request.subThemes.map(getSubThemes)}
								</div>
							</div>
						</>
					}
				>
					<div
						className={joinClasses([
							pageUtils.classes.textContent,
							pageUtils.classes.panel
						])}
					>
						<Text content={request.description} />
						<button
							onClick={onNavigateToRequest(request.requestId)}
							className={pageUtils.classes.btn}
						>
							{formatStrapiText(
								pageData?.assets[pageUtils.buttonTextDic[group]]
							)}
						</button>
					</div>
				</Collapse>
			</div>
		)
	}

	return (
		<>
			<PageTitle
				title={`${formatStrapiText(pageData?.title)} - ${formatStrapiText(
					pageSubtitle
				)}`}
			/>
			<SectionTitle title={formatStrapiText(pageSubtitle)} />
			{loading ? (
				<Loader text="Loading" />
			) : (
				<section className={pageUtils.classes.section}>
					{group !== 'registrationsAndApplications' && (
						<>
							<div className={pageUtils.classes.subtitle}>
								{theme?.description}
							</div>

							<div className={pageUtils.classes.filter}>
								<OutsideClickHandler
									onOutsideClick={() => setShowDropdown(false)}
								>
									<button
										onClick={() => setShowDropdown(!showDropdown)}
										className={joinClasses([
											pageUtils.classes.outlineButton,
											showDropdown ? pageUtils.classes.buttonActive : ''
										])}
									>
										{pageData.assets?.page_create_request_filter_by_category}
										{''}
										<ChevronDown />
									</button>

									<div
										className={joinClasses([
											pageUtils.classes.dropdown,
											showDropdown ? pageUtils.classes.dropdownActive : ''
										])}
									>
										<form ref={filterRef}>
											<fieldset className={pageUtils.classes.checkbox}>
												{theme?.requestSubThemes.map((subTheme, index) => (
													<div
														key={`dropdown-${subTheme.name}`}
														className={pageUtils.classes.checkboxContainer}
													>
														<input
															onChange={(e) => handleFilter(e, subTheme.name)}
															type="checkbox"
															id={`category-${index}`}
															className={pageUtils.classes.checkboxInputs}
														/>
														<label
															className={pageUtils.classes.label}
															htmlFor={`category-${index}`}
														>
															{subTheme.title}
														</label>
													</div>
												))}
											</fieldset>
										</form>
									</div>
								</OutsideClickHandler>
							</div>
						</>
					)}

					{displayedRequests?.map(displayRequests)}

					{isOtherRequestAvailable && otherRequest && (
						<div className={pageUtils.classes.otherRequest}>
							<Text content={otherRequest.description} />
							<Link
								to={`/auth/requests/create/${otherRequest.requestId}`}
								className={pageUtils.classes.button}
								onClick={onNavigateToRequest(otherRequest.requestId)}
							>
								<Text
									content={
										pageData?.assets?.page_create_request_other_request_button
									}
								/>
							</Link>
						</div>
					)}
				</section>
			)}
		</>
	)
}

export default CreateRequest
